
<template>
 <div v-if="!($route.path.startsWith('/registration/') || $route.path.startsWith('/question/') ||$route.path.startsWith('/question_modalitas_belajar/')||$route.path.startsWith('/question_minat_bakat/') ||$route.path.startsWith('/question_papi_kostick/') || $route.path.startsWith('/logintest'))"
    class="subheader pb-2 pb-lg-4"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  > 
    <div 
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ title }}
        </h5>
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
        >
          <li class="breadcrumb-item">
            <router-link :to="'/'" class="subheader-breadcrumbs-home">
              <i class="flaticon2-shelter text-muted icon-1x"></i>
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li class="breadcrumb-item" :key="`${i}-${breadcrumb.id}`">
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span class="text-muted" :key="i" v-if="!breadcrumb.route">
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div class="d-flex align-items-center">
        <b-dropdown
        v-if="showActions"
        size="sm"
        variant="link"
        toggle-class="custom-v-dropdown"
        no-caret
        right
        no-flip
        text="Actions"
        v-b-tooltip.hover="'Quick actions'"
        ref="myDropdown"
      >
        <template v-slot:button-content>
          <a href="#" class="btn btn-icon" data-toggle="dropdown">
            <span class="svg-icon svg-icon-success svg-icon-2x">
              <inline-svg src="media/svg/icons/Files/File-plus.svg" />
            </span>
          </a>
        </template>
        <!-- Navigation -->
        <div class="navi navi-hover min-w-md-650px">
          <b-dropdown-text tag="div" class="navi-header font-weight-bold">
            Add Data Question Type:
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
            <a href="#" class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-folder"></i>
              </span>
              <b-form-input
                size="sm"
                placeholder="Enter your Question type name"
                v-model="add_data_question.question_type_name"
              />
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item">
            <a href="#" class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-clip-symbol"></i>
              </span>
              <b-form-input
                size="sm"
                placeholder="Enter your Question Type Description"
                v-model="add_data_question.question_type_description"
              />
            </a>
          </b-dropdown-text>
          <b-dropdown-text tag="div" class="navi-item">
            <a  class="navi-link">
              <span class="navi-icon">
                <i class="flaticon2-list"></i>
              </span>
              <b-form-select size="sm" v-model="add_data_question.question_type_of_type" :options="options"></b-form-select>
            </a>
          </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-footer">
              <a
                class="btn btn-light-primary font-weight-bolder btn-sm"
                @click="saveData"
                >Save</a
              >
              <a
                class="btn btn-clean font-weight-bold btn-sm"
                href="#"
                data-toggle="tooltip"
                data-placement="left"
                v-b-tooltip.hover
                @click="cancelAction"
                >Cencel</a
              >
            </b-dropdown-text>
        </div>
        <!-- End Navigation -->
      </b-dropdown>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_MASTER_TypeTest} from "@/core/services/store/mTypeTest.module";
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'
import EventBus from '@/EventBus';
export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String,
    route: String,
  },
  data() {
    return {
      showActions: this.$route.path == "/typetest",
      isDropdownOpen: false,
      add_data_question: {
            question_type_name: "",
            question_type_description: "",
            question_type_of_type: null,
          },
      options: [
            { value: 'CFIT', text: 'CFIT' },
            { value: 'MODALITAS_BELAJAR', text: 'Modalitas Belajar' },
            { value: 'MINAT_BAKAT', text: 'Minat Bakat' },
            { value: 'PAPI_KOSTICK', text: 'Papi Kostick' },
          ],
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
  },
  watch: {
    '$route.path': function(newPath) {
      this.showActions = newPath == "/typetest";
    }
  },
  methods: {
    saveData() {
      const formData = new FormData();
      formData.append("TypeTestName", this.add_data_question.question_type_name);
      formData.append("TypeTestDes", this.add_data_question.question_type_description);
      formData.append("TypeOfTypeTest", this.add_data_question.question_type_of_type);

      let contentType = `application/form-data`;

      Services.PostData(
        ApiService,
        `master/typetests/`,
        formData,
        contentType,
        // onSuccess function
        response => {
          if (response.status) {
            Swal.fire({
              title: "",
              text: "Data saved successfully.",
              icon: "success",
              heightAuto: true,
              timer: 1500
            });
            // Setelah berhasil save, update langsung state di Vuex
            this.$store.commit(SET_MASTER_TypeTest, response.data);
            EventBus.$emit('data-saved');
          } else {
            Swal.fire({
              title: "",
              text: response.data.error,
              icon: "info",
              heightAuto: true,
              timer: 1500
            });
          }
        },
        // onError function
        error => {
          console.error('Error during API call:', error);
          // Handle unexpected errors
        }
      )
      .finally(() => {
        // Hide the dropdown regardless of success or failure
        this.$nextTick(() => {
          this.$refs.myDropdown.hide(); // Menutup dropdown secara manual
        });
      });
    },



    cancelAction() {
      this.$nextTick(() => {
        this.$refs.myDropdown.hide(); // Menutup dropdown secara manual
      });
      console.log('Action canceled');
    },
  },
};
</script>
